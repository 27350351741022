<template>
    <nav>

        <v-toolbar flat color="primary" height="33px">
            <v-spacer></v-spacer>
            <span class="white--text caption">06 - 12 99 92 24 | info@tonbierman.nl | btw-id: NL001763841B39</span>
            <v-spacer></v-spacer>
        </v-toolbar>

        <v-toolbar flat color="primary">
            <v-app-bar-nav-icon class="white--text hidden-lg-and-up" @click="drawer = !drawer"></v-app-bar-nav-icon>
            <v-toolbar-title class="text-uppercase white--text">
            </v-toolbar-title>

            <router-link to="/">
                <v-img
                class="hidden-md-and-down"
                src="../assets/Homepage/logoapartvolledig.png"
                width="400"
                height="80">
                </v-img>

                <v-img
                class="hidden-lg-and-up"
                src="../assets/Homepage/logoapartvolledig.png"
                width="300"
                height="80">
                </v-img>

            </router-link>

        <v-spacer></v-spacer>
            <v-btn width="10%" text class="ma-12 white--text hidden-md-and-down" :to="'/'">Home</v-btn>
            <v-btn width="10%" text class="ma-12 white--text hidden-md-and-down" :to="'work'">Werkzaamheden</v-btn>
            <v-btn width="10%" text class="ma-12 white--text hidden-md-and-down" :to="'team'">Over Ons</v-btn>
            <v-btn width="10%" text class="ma-12 white--text hidden-md-and-down" :to="'contact'">Contact</v-btn>
        <v-spacer></v-spacer>
        </v-toolbar>

        <v-navigation-drawer disable-resize-watcher class="primary" v-model="drawer" app persistent>
            <v-layout column align-center>
                <v-flex class="mt-5">
                    <v-avatar tile size="100">
                        <img
                        src="../assets/logoTB.png"
                        alt="logoTB"
                        >
                    </v-avatar>
                    <p class="white--text subheading mt-1">
                        Ton Bierman
                    </p>
                </v-flex>
            </v-layout>
            <v-list>
                <v-list-item v-for="link in links" :key="link.text" router :to="link.route">
                <v-list-item-icon>
                    <v-icon class="white--text">{{ link.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title class="white--text">{{ link.text }}</v-list-item-title>
                </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
    </nav>
</template>

<script>
export default {
    // #62CAF1
    data() {
        return {
            drawer: false,
            links: 
            [
                { icon: 'mdi-home', text: 'Home', route: '/' },
                { icon: 'mdi-tools', text: 'Werkzaamheden', route: '/work' },
                { icon: 'mdi-account', text: 'Over ons', route: '/team' },
                { icon: 'mdi-phone', text: 'Contact', route: '/contact'}
            ]
        }
    }
}
</script>