<template>
    <v-dialog hide-overlay width=unset v-model="dialog">

    <template v-slot:activator="{ on }">
        <v-btn text icon v-on="on">
            <v-icon color="white">zoom_in</v-icon>
        </v-btn>
    </template>

    <v-card flat height="100%" max-width="100%" class="mx-auto">
        <v-card-actions>
            <v-img
            :src="getImgUrl(this.imageLink)"
            width="100%"
            height="100%"
            >
            <v-btn icon x-large @click="dialog=false">
                <v-icon style="position:fixed;" color="white">close</v-icon>
            </v-btn>
            </v-img>
        </v-card-actions>
    </v-card>

    </v-dialog>
</template>

<script>

export default {
    props: {
        sentImageLink: {
            type:String,
            required:true,
        },
    },

    async mounted()
    {
        this.imageLink = this.sentImageLink;
    },

    data() {
        return {
            dialog:false,
            imageLink:"VCA.png",
        }
    },

    methods : {
        getImgUrl(img) {
        return require('@/assets/Workpage/' + img);
      },
    }
}
</script>