<template>
  <div class="work">
    <div class="text-center mt-5">
      <span class="display-1">Hierbij enkele voorbeelden van laswerk en andere werkzaamheden.</span>
      <br />
      <span class="subheading font-italic font-weight-light">druk op het</span>
      
        <v-btn disabled text icon>
          <v-icon color="white">zoom_in</v-icon>
        </v-btn>
      
      <span class="subheading font-italic font-weight-light">icoon om de volledige foto te bekijken.</span>
    </div>

    <v-container fluid class="ma-5">

      <v-row dense>
        <v-col md="4" cols="11"
        v-for="picture in pictures"
        :key="picture.source"
        >
          <v-card max-width="500" class="mb-4 white--text" color="primary">
            <v-img
            :src="getImgUrl(picture.source)"
            height="200px"
            width="500px">
            <work-image :sentImageLink="picture.source" />
            </v-img>
            <v-card-title>{{picture.title}}</v-card-title>
            <v-card-subtitle class="white--text">{{picture.subtitle}}</v-card-subtitle>
          </v-card>
        </v-col>
      </v-row>

    </v-container>
    
    <div class="text-center mb-5">
      <v-btn class="success white--text" :to="'/contact'">NAAR CONTACT</v-btn>
    </div>
  </div>
</template>

<script>
import WorkImage from '../components/WorkImage.vue';
export default {
  components: {WorkImage},
    // #62CAF1
    data() {
        return {
            pictures: 
            [
                { title: 'RVS pijpstukken', subtitle: '', source: "rvspijpstukken.jpg" },
                { title: 'Laswerk', subtitle: '', source: "laswerkpijpleiding1.jpg" },
                { title: 'Aanboren onder druk', subtitle: '', source: "AOD2.jpg"},
                { title: 'RVS zuil', subtitle: '', source: "zuil.jpg"},
                { title: 'Trapleuning nieuwbouw', subtitle: '', source: "trapleuning.jpg"},
                { title: 'Loopbrug', subtitle: '', source: "loopbrug.jpg"},
            ]
        }
    },
    methods: {
      getImgUrl(img) {
        return require('@/assets/Workpage/' + img);
      }
    }
}
</script>
