<template>
  <div class="home">

    <v-card tile>
      <v-img
      src="../assets/Homepage/welding.webp"
      width="1920"
      height="550"
      gradient="to top right, rgba(100,255,255,.1), rgba(25,32,72,0.01)"
      >
      <v-img src="../assets/Homepage/logoapartvolledig.png"
      class="hidden-sm-and-down"></v-img>
      </v-img>
    </v-card>

    <v-container class="grey lighten-2" fluid>

      <v-row justify="space-around" class="ma-0">

        <v-card color="grey lighten-2" flat>
          <v-img src="../assets/Homepage/logoapart.png" height="360px"></v-img>
        </v-card>


        <v-col md="6" sm="7" class="pb-5">
          <span class="display-1 font-weight-regular text-uppercase">Zoekt u een ervaren ZZP-er voor aanboringen onder druk, las- of fitwerk?</span>
          <v-col cols="12" class="homeDiv">
            <br />
            <p class="title">
              Dan bent u bij mij aan het juiste adres.
              <br />
              <br />
              Voor het oplassen van nieuwe aansluitingen op onder druk staande leidingen en voor diverse las- en fitwerkzaamheden kunt u zowel als bedrijf als particulier bij mij terecht.
              <br />
              <br />
              <v-btn class=" my-2 success white--text" :to="'/contact'">NAAR CONTACT</v-btn>
            </p>
          </v-col>

          </v-col>
      </v-row>

    </v-container>

    <v-container class="grey lighten-2" fluid>

      <v-row justify="space-around" class="ma-5">
        <v-card height="100%" width="800" >
          <v-img
          src="../assets/Workpage/AOD4.png"
          >
          </v-img>
        </v-card>

        <v-col xl="6" md="5" sm="7" class="pb-5 " >
          <v-col>
            <span class="display-1 font-weight-regular text-uppercase">Aanboren onder druk</span>
            <br />
            <br />
            <p class="title">Aanboringen onder druk; een specialisme wat ook valt onder Ton Bierman Montage en Lastechnieken.
              <br/>
              <br/>
              Met deze aanboortechniek kunnen op onder druk staande leidingen, tanks of andere installaties, nieuwe aansluitingen gerealiseerd worden.
              <br/>
              Deze techniek kan toegepast worden op CV- en koelwaterleidingen, gas- en stoomleidingen van staal, RVS of kunststof.
              <br/>
              <br/>
              De leidingen hoeven dus niet afgetapt of drukloos gemaakt te worden en de installatie blijft dus in bedrijf.
            </p>
          </v-col>
        </v-col>
      </v-row>

    </v-container>

    <v-container class="grey lighten-2" fluid>

      <v-row justify="space-around" class="ma-5">
        <v-card width="800" height="100%" >
          <v-img
          src="../assets/Workpage/rvspijpstukken2.png"
          height="400"
          >
          </v-img>
        </v-card>

        <v-col xl="6" md="5" sm="7" class="pb-5" >
          <v-col>
            <span class="display-1 font-weight-regular text-uppercase">Las- en fitwerk</span>
            <br />
            <br />
            <p class="title">Het MIG/MAG lassen van staal en het TIG lassen van Aluminium en Roestvast Staal zijn enkele voorbeelden van technieken die ik beheers.
              <br/>
              <br/>
              Het lassen van Cortenstaal voor bijvoorbeeld bloembakken, behoort ook tot één van de vele mogelijkheden. 
              <br />
              <br />
              Tevens ben ik in het bezit van een dieselaggregaat wat lassen op locaties zonder stroom ook mogelijk maakt.
              <br/>
            </p>
          </v-col>
        </v-col>
      </v-row>

    </v-container>

    <v-card tile>
      <v-img
      src="../assets/Homepage/huis.jpg"
      width="1920"
      height="450"
      >
      </v-img>
    </v-card>

    

    <v-container class="grey darken-2" fluid>
      <v-row class="pa-5" justify="center">
        <span class="hidden-sm-and-down font-weight-thin white--text text-uppercase display-2">Waarom Ton Bierman?</span>
        <span class="hidden-md-and-up font-weight-thin white--text text-uppercase display-1">Waarom Ton Bierman?</span> 
      </v-row>

      <v-row class="pa-5 pb-0 text-truncate" justify="center">
        
        <v-col lg="2" md="3" xl="2" sm="5" justify-self="center" align-self="center" >

          <v-icon x-large color="success" class="mb-3">check</v-icon>
          <span class="white--text font-weight-light title ">Aanboren onder druk</span>
            <br/>

          <v-icon x-large color="success" class="mb-3">check</v-icon>
          <span class="white--text font-weight-light title">Gecertificeerd lasser</span>
            <br/>

          <v-icon x-large color="success" class="mb-3">check</v-icon>
          <span class="white--text font-weight-light title">Beheerst diverse lastechnieken</span>
            <br/>

          <v-icon x-large color="success" class="mb-3">check</v-icon>
          <span class="white--text font-weight-light title">Inzetbaar op locaties zonder stroom</span>
            <br/>

          <v-icon x-large color="success" class="mb-3">check</v-icon>
          <span class="white--text font-weight-light title">VCA gecertificeerd</span>
            <br/>
            
          <v-icon x-large color="success" class="mb-3">check</v-icon>
          <span class="white--text font-weight-light title">BHV certificaat</span>

            <v-img
            src="../assets/Homepage/VCA.png" width="340px" height="150px" contain></v-img>

        </v-col>

      </v-row>

    </v-container>

  </div>
</template>
<style scoped>

.werkplaatsDiv {
  width: 450px;
}
.rowText {
  width: 200px;
}
.homeDiv {
  width:650px;
}
</style>

<script>

export default {
}
</script>
