<template>
  <v-app class="grey lighten--4">
    <Navbar />
    <v-content>
      <router-view></router-view>
    </v-content>

    <v-footer padless class="mt-0">
    <v-col
      class="text-center primary white--text"
      cols="12"
    >

      <v-btn text class="white--text mb-1" rounded small :to="'/'">Home</v-btn>
        <v-spacer></v-spacer>

      <v-btn text class="white--text mb-1" rounded small :to="'/work'">Werkzaamheden</v-btn>
        <v-spacer></v-spacer>

      <v-btn text class="white--text mb-1" rounded small :to="'/team'">Over Ons</v-btn>
        <v-spacer></v-spacer>

      <v-btn text class="white--text mb-1" rounded small :to="'/contact'">Contact</v-btn>
        <v-spacer></v-spacer>

      <strong class="caption">{{new Date().getFullYear()}} - ©Ton Bierman Montage en Lastechnieken</strong>

    </v-col>
  </v-footer>

  </v-app>
</template>

<script>
import Navbar from '@/components/Navbar'

export default {
  name: 'App',
  components: {Navbar},
  data: () => ({
    //
  }),
};
</script>
